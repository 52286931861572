<template>
  <span v-if="errors.length>0" class="invalid-feedback d-block">
    <span v-for="(error, index) in errors" :key="index" class="d-block">{{ error }}</span>
  </span>
</template>

<script>
export default {
  name: "InputFeedback",
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  }
}
</script>