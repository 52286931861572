<template>
  <label class="form-label">{{ label }}: <span v-if="required" class="text-danger">*</span></label>
</template>

<script>
export default {
  name: "FormLabel",
  props: {
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>