<template>
  <div></div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "Logout",
  methods: {
    ...mapActions("user", ["logout"]),
  },
  mounted() {
    this.logout().then(() => {
      this.$router.push({name: 'Login'})
    });
  }
}
</script>