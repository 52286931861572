import {createRouter, createWebHashHistory} from 'vue-router'
import routes from "@/router/routes";
// import store from "@/store"


const router = createRouter({
    // base: process.env.BASE_URL,
    history: createWebHashHistory(),
    routes
})

/*
* Checking user role is exist in authorize property at URL routes object
* if found can user accessible
* otherwise user can't access the URL routes
* else routes redirect to 404 page
* */

router.beforeEach(async (to, from, next) => {
    // const {authorize} = to.meta;
    // if (store.getters["user/auth"].login) {
    //     const user = JSON.parse(localStorage.getItem('user'));
    //     if (authorize && !authorize.includes(user.user_role)) {
    //         return next({name: 'DashboardNotFound'})
    //     }
    // }
    document.title = to.meta.title ? `${to.meta.title} - ${process.env.VUE_APP_NAME}` : process.env.VUE_APP_NAME
    next();
});

export default router
