import Home from "@/views/Home";
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import NotFound from "@/views/NotFound";
import Editor from "@/views/Editor";
let routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        redirect: {name: 'Editor'},
        meta: {
            title: 'Editor'
        },
        children: [
            {
                path: 'editor',
                component: Editor,
                name: 'Editor',
                meta: {
                    title: 'Editor'
                }
            },
            {
                path: '/404',
                name: 'DashboardNotFound',
                component: NotFound
            },
        ]
    },

    {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'Login'
        },
        component: Login
    },
    {
        path: '/logout',
        name: 'Logout',
        meta: {
            title: 'Logout'
        },
        component: Logout
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound
    },
    {
        path: '/*',
        redirect: {name: 'Editor'},
    },
];
export default routes