<template>
  <svg ref="svg" class="svg"></svg>
</template>

<script>
import {computed, onMounted, ref, toRefs, watch} from "vue";
import {SVG} from "@svgdotjs/svg.js";
import {useStore} from "vuex";

export default {
  name: "SvgGenerator",
  props: {
    title: {
      type: String,
      default: 'BORBOS ELITE'
    },
    subtitle: {
      type: String,
      default: 'FUNGICIDA'
    },
    color: {
      type: String,
      default: '#00b2e2'
    },
    padding: {
      type: Number,
      default: 25
    }
  },
  setup(props) {
    const store = useStore();

    const {title, subtitle, color, padding} = toRefs(props);
    const svg = ref(null);

    const svgPadding = padding.value;
    // const svgPaddingMarginY = 10;
    const textTitleLeft = 12;
    const textSubtitleRectMarginTop = 24 * 2

    watch(props, function () {
      drawSVG();
    })

    const auth = computed(() => store.getters['user/auth']);

    onMounted(async () => {
      if (auth.value.login) {
        setTimeout(function () {
          initSVG()
        }, 2000)
      }
    });

    let draw = null;
    let groupHeader = null;
    let textTitle = null;
    let textTitleBBox = null;
    let groupIcon = null;
    let groupRect = null;
    let textSubtitle = null;
    let textSubtitleRect = null;


    function initSVG() {
      draw = SVG(svg.value)
      groupHeader = draw.group();
      textTitle = groupHeader.text(title.value).font({family: 'Rubik', size: 211.02, weight: 900});
      textTitle.cy(positiveWithPadding(textTitle.cy()))
      textTitle.transform({translateX: svgPadding - textTitleLeft})
      textTitleBBox = textTitle.node.getBBox();

      groupIcon = groupHeader.group();
      groupIcon.path('M533.2,168.2v-19.3c-6.1,0.9-32.2,7.7-40.1,25.8v0.3h40.1V168.2z')
      groupIcon.path('M533.2,145.1v-2.9c-24.7,0-36.9,6.8-39.5,8.4c-0.2,0.1-0.4,0.2-0.6,0.4v9.7 C497.4,156.5,509.1,146.6,533.2,145.1')
      groupIcon.path('M526.1,138L526.1,138c0.4,0,0.7,0,1,0h6.1v-3.1h-40.1v6.6C503.7,138.4,518.9,138,526.1,138')
      groupIcon.width(148.102)
      groupIcon.height(148.102)
      groupIcon.move(textTitleBBox.width + textSubtitleRectMarginTop, svgPadding - 2.5);
      groupIcon.fill(color.value);

      groupRect = draw.group();
      textSubtitle = groupRect.text(subtitle.value).font({family: 'Rubik', size: 58.21, weight: 700})
      textSubtitle.attr('letter-spacing', '-1px');
      textSubtitle.cy(positiveWithPadding(textSubtitle.cy()) + (textTitleBBox.height + textSubtitle.node.getBBox().height) / 2 + textSubtitleRectMarginTop - 10);
      textSubtitle.transform({translateX: svgPadding + 15});
      textSubtitle.fill('#ffffff')

      textSubtitleRect = groupRect.rect(groupHeader.width() - textTitleLeft, textSubtitle.node.getBBox().height + 4)
      textSubtitleRect.cy(textSubtitle.cy())
      textSubtitleRect.move(svgPadding)
      textSubtitleRect.insertBefore(textSubtitle)
      textSubtitleRect.fill(color.value);
      draw.width(textSubtitleRect.width() + svgPadding * 2)
      draw.height(groupHeader.height() + svgPadding * 2)
    }


    function drawSVG() {
      if (draw) {
        textTitle.node.innerHTML = title.value
        textSubtitle.node.innerHTML = subtitle.value;

        // let textTitleWidth = textTitle.node.getBBox().width + iconPadding;
        // let groupHeaderWidth = textTitleWidth + (groupIcon.width() / 2) + svgPadding + iconPadding;
        // let textSubtitleRectWidth = groupHeaderWidth;
        //
        // if (textSubtitle.node.getBBox().width + svgPadding > groupHeaderWidth) {
        //   textSubtitleRectWidth = textSubtitle.node.getBBox().width + svgPadding;
        //   textTitleWidth = textSubtitleRectWidth - groupIcon.width() / 2 - svgPadding
        // }
        // textSubtitleRect.width(textSubtitleRectWidth);
        // groupIcon.move(textTitleWidth, svgPadding);

        let textTitleWidth = textTitle.node.getBBox().width + textSubtitleRectMarginTop;
        let groupHeaderWidth = textTitleWidth + (groupIcon.width() / 2) + svgPadding + textSubtitleRectMarginTop;
        let textSubtitleRectWidth = groupHeaderWidth - svgPadding //textTitle.node.getBBox().width + textSubtitleRectMarginTop + groupIcon.width() - svgPadding;

        if (textSubtitle.node.getBBox().width + svgPadding > groupHeaderWidth) {
          textSubtitleRectWidth = textSubtitle.node.getBBox().width + svgPadding
          textTitleWidth = textSubtitleRectWidth - groupIcon.width() + svgPadding  // / 2 - svgPadding + textSubtitleRectMarginTop
        }

        groupIcon.move(textTitleWidth, svgPadding - 2.5);
        textSubtitleRect.width(textSubtitleRectWidth);
        groupIcon.fill(color.value);
        textSubtitleRect.fill(color.value)
        draw.width(textSubtitleRect.width() + svgPadding * 2)
        draw.height(groupHeader.height() + svgPadding * 2)
      }
    }

    function positiveWithPadding(value) {
      return (value * -1) + 1 + svgPadding;
    }

    return {
      svg
    }
  }
}
</script>

<style scoped>

</style>