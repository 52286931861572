/**
 * @param {ref} object
 * @param {ref} required - required array for form data
 * @param {ref} errors - form data validate and store to errors object
 * @return {Boolean} - return value as boolean
 */
function validateData(object, required, errors) {
    let not_errors = true;
    errors.value = {};
    required.value.forEach(function (key) {
        let obj = object.value[key];
        let obj_error = false;

        errors.value[key] = []
        if (obj === null) {
            obj_error = true;
        } else if (Array.isArray(obj)) {
            if (obj.length === 0) {
                obj_error = true;
            }
        } else if (typeof obj === "object") {
            if (Object.keys(obj).length === 0) {
                obj_error = true;
            }
        } else {
            if (typeof obj === "string") {
                obj = obj.trim();
            }
            if (obj === "" || obj === null) {
                obj_error = true;
            }
        }

        if (obj_error) {
            if (not_errors) {
                not_errors = false;
            }
            errors.value[key].push("This field is required");
        }
    });
    return not_errors
}
export {
    validateData
}