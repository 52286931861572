function ucWords(str) {
    return str.replaceAll('_', ' ').replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
}

function camelCase() {
    //
}

function snakeCase(str) {
    return matchCase(str)
        .join('_')
        .toLowerCase();
}

function kebabCase(str) {
    return matchCase(str)
        .join('-')
        .toLowerCase();
}

function pascalCase(str) {
    return matchCase(str)
        .join('')
        .toLowerCase();
}

function matchCase(str) {
    return str.toString()
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
}

export {
    ucWords,
    camelCase,
    snakeCase,
    kebabCase,
    pascalCase,
}