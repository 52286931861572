<template>
  <div class="mb-3">
    <FormLabel v-if="has_label" :label="label" :required="required"/>
    <div>
      <div v-for="(option, index) in options" :key="index" class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" :name="kebabCase(label)" :id="idPrefix+getId(option)" :value="getValue(option)" :checked="isChecked(option)" @input="inputChanged($event.target, option)">
        <label class="form-check-label form-label" :for="idPrefix+getId(option)">{{ getText(option) }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import FormLabel from "./FormLabel";
import {kebabCase} from "@/assets/script/type_cases"
import {toRefs} from "vue";
import _ from "lodash";

export default {
  name: "FormGroupCheckBox",
  components: {FormLabel},
  props: {
    label: {
      type: String,
      default: ""
    },
    has_label: {
      type: Boolean,
      default: () => true
    },
    required: {
      type: Boolean,
      default: () => false
    },
    checked: {
      type: Boolean,
      default: () => false
    },
    returnBoolean: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: String,
      default: ""
    },
    idPrefix: {
      type: String,
      default: ""
    },
    options: {
      type: [Object, Array],
      default: () => []
    },
    optionValue: {
      type: String,
      default: ""
    },
    optionText: {
      type: String,
      default: ""
    },
    selectedOption: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  setup(props, {emit}) {
    let {optionValue, optionText, returnBoolean, checked, selectedOption} = toRefs(props);


    function getValue(option) {
      if (optionValue.value !== '') {
        return option[optionValue.value];
      }
      return getText(option)
    }

    function getText(option) {
      if (optionText.value !== '') {
        return option[optionText.value];
      }
      return option;
    }

    function getId(option) {
      return 'checkbox-' + kebabCase(getValue(option))
    }

    function isChecked(option) {
      if (returnBoolean.value) {
        return checked.value;
      } else if (selectedOption.value.length > 0) {
        return selectedOption.value.indexOf(getValue(option)) > -1;
      } else {
        return _.get(option, 'selected', false);
      }
    }

    function inputChanged(target) {
      if (returnBoolean.value) {
        emit('input-value', target.checked);
      } else {
        console.log(target.value)
        emit('input-value', target.value);
        // option.selected = !_.get(option, 'selected', false);
      }
    }

    return {
      getId,
      getValue,
      getText,
      kebabCase,
      isChecked,
      inputChanged,
    }
  }
}
</script>