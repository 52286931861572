<template>
  <div class="mb-3">
    <FormLabel :label="label" :required="required"/>
    <div class="d-flex flex-column">
      <div v-for="(option, index) in options" :key="index" class="form-check form-check-inline">
        <input class="form-check-input" type="radio" :name="kebabCase(label)" :id="getId(option)" :value="getValue(option)" :checked="getValue(option)===value" @input="optionChanged(option)">
        <label class="form-check-label form-label" :for="getId(option)">{{ getText(option) }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import FormLabel from "./FormLabel";
import {kebabCase} from "@/assets/script/type_cases"
import {toRefs} from "vue";

export default {
  name: "FormGroupRadio",
  components: {FormLabel},
  props: {
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: String,
      default: ""
    },
    options: {
      type: [Object, Array],
      default: () => []
    },
    optionValue: {
      type: String,
      default: ""
    },
    optionText: {
      type: String,
      default: ""
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  setup(props, {emit}) {
    let {optionValue, optionText} = toRefs(props);


    function getValue(option) {
      if (optionText.value !== '') {
        return option[optionValue.value];
      }
      return getText(option)
    }

    function getText(option) {
      if (optionText.value !== '') {
        return option[optionText.value];
      }
      return option;
    }

    function getId(option) {
      return kebabCase(getValue(option))
    }

    function optionChanged(option) {
      if (typeof option === 'object') {
        emit('input-value', option);
      } else {
        emit('input-value', getValue(option));
      }
    }

    return {
      getId,
      getValue,
      getText,
      optionChanged,
      kebabCase
    }
  }
}
</script>