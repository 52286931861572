<template>
  <main>
    <h1 class="text-center my-5 h3 fw-normal">Please Sign In</h1>
    <form @submit.prevent="submit">
      <FormGroupInput
          :has-label="false"
          type="password"
          label="Password"
          :attributes="{class: 'form-control-lg text-center'}"
          :required="required.includes('password')"
          :value="form_data.password"
          :errors="errors.password"
          @input-value="form_data.password=$event"/>
      <button type="submit" class="btn btn-primary w-100 btn-lg">Sign in</button>

    </form>
  </main>
</template>

<script>
import FormGroupInput from "@/components/form-component/FormGroupInput";
import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import {validateData} from "@/assets/script/helper";
import {useRouter} from "vue-router";

export default {
  name: "Login",
  components: {FormGroupInput},
  mounted() {
    document.body.classList.add('form-signin')
  },
  unmounted() {
    document.body.classList.remove('form-signin')
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const form_data = ref({
      password: ''
    });
    const required = ref(['password']);
    const errors = ref({});
    const errors_message = ref("");

    const fetchedErrors = computed(() => store.getters['user/getErrorResponse']);
    const isLoading = computed(() => store.getters["user/isLoading"]);
    const auth = computed(() => store.getters['user/auth']);

    async function submit() {
      errors.value = {};
      errors_message.value = "";
      let validated = validateData(form_data, required, errors);
      if (validated) {
        await store.dispatch('user/login', form_data.value);
        if (fetchedErrors.value.isError) {
          errors.value = fetchedErrors.value.errors;
          errors_message.value = fetchedErrors.value.message;
        } else {
          await router.push({name: "Editor"});
        }
      }
    }

    onBeforeMount(async () => {
      if (auth.value.login) {
        await router.push({name: 'Editor'});
      }
    });

    return {
      form_data,
      required,
      errors,
      errors_message,
      submit,
      isLoading
    }
  }
}
</script>