import handleError from "@/axios/handle_error";
import {error, pagination} from "@/store/helper";
import api from "@/axios/user";

const state = {
    auth: {
        login: false,
        token: '',
    },
    ...error.state,
    ...pagination.state
};

const getters = {
    auth(state) {
        let token = localStorage.getItem('token');
        state.auth.login = !(token === null || token === '');
        return state.auth;
    },
    ...error.getters,
    ...pagination.getters,
};
const actions = {
    async login({commit}, login) {
        commit('startLoading');
        try {
            const response = await api.login(login);
            commit('setAuthToken', response.data.token);
            commit('setAuthLogin', true);
        } catch (error) {
            commit('setErrorResponse', handleError(error));
        }
        commit('stopLoading');
    },
    async logout({commit}) {
        if (state.auth.login) {
            commit('startLoading');
            try {
                commit('setAuthToken', '');
                commit('setAuthLogin', false);
                commit('setAuthUser', {});
            } catch (error) {
                commit('setErrorResponse', handleError(error));
            }
            commit('stopLoading');
        }
    },
};
const mutations = {
    setAuthToken: function (state, token) {
        localStorage.setItem('token', token);
        state.auth.token = token;
    },
    setAuthLogin: (state, is_login) => state.auth.login = is_login,
    setAuthUser: function (state, user) {
        localStorage.setItem('user', JSON.stringify(user));
        return state.auth.user = user;
    },
    ...error.mutations,
    ...pagination.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}