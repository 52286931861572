<template>
  <div class="mb-3">
    <FormLabel v-if="hasLabel" :label="label" :required="required"/>
    <input
        ref="ref_input"
        :type="type"
        v-bind="attributes"
        class="form-control"
        :class="{'is-invalid':errors.length>0}"
        :value="value"
        :placeholder="placeholder?placeholder:'Enter '+label.toLowerCase()"
        :disabled="disabled"
        @input="onInput($event.target.value)"
    >
    <InputFeedback :errors="errors"/>
    <div v-if="type==='password'">
      <div v-if="hasGeneratePassword">
        <input type="button" class="btn btn-outline-secondary btn-sm mt-2 mr-1" value="Generate Password" @click="generatePassword">
        <input ref="ref_copy_password_btn" type="button" class="btn btn-outline-secondary btn-sm mt-2" value="Copy Password" @click="copyPassword">
      </div>
      <FormGroupCheckBox
          v-if="hasShowPassword"
          class="mb-0 mt-2"
          :options="['Show Password']"
          :has_label="false"
          :return-boolean="true"
          :checked="password_showed"
          @input-value="showPassword"/>
    </div>
  </div>
</template>

<script>
import InputFeedback from "./InputFeedback";
import FormLabel from "./FormLabel";
import FormGroupCheckBox from "./FormGroupCheckBox";
import {ref, toRefs} from "vue";

export default {
  name: "FormGroupInput",
  components: {FormGroupCheckBox, FormLabel, InputFeedback},
  props: {
    attributes: {
      type: Object,
      default: () => ({})
    },
    label: {
      type: String,
      default: ""
    },
    hasLabel: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: () => false
    },
    hasShowPassword: {
      type: Boolean,
      default: () => false
    },
    hasGeneratePassword: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: String,
      default: "text"
    },
    value: {
      type: [String, Number],
      default: ""
    },
    placeholder: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  setup(props, {emit}) {
    const {value} = toRefs(props);
    const password_showed = ref(false);
    const copied = ref(false);
    const ref_input = ref(null);
    const ref_copy_password_btn = ref(null);

    function showPassword(forced = false) {
      if (forced || ref_input.value.type === "password") {
        ref_input.value.type = "text"
        password_showed.value = true;
      } else {
        ref_input.value.type = "password";
        password_showed.value = false;
      }
    }

    function generatePassword() {
      copied.value = false;
      showPassword(true);
      // onInput(generate());
    }

    function copyPassword() {
      ref_input.value.type = "text"
      password_showed.value = true;

      const btn = ref_copy_password_btn.value;
      let btn_value = btn.value;
      btn.value = 'Copied';
      btn.setAttribute('disabled', '');
      const password = value.value;
      if (password) {
        ref_input.value.focus();
        ref_input.value.setSelectionRange(0, password.length);
        document.execCommand("copy");
        ref_input.value.blur();
      }
      setTimeout(function () {
        btn.value = btn_value;
        btn.removeAttribute('disabled');
        copied.value = true;
      }, 750)
    }

    function onInput(value) {
      emit('input-value', value)
    }

    return {
      password_showed,
      ref_input,
      ref_copy_password_btn,
      showPassword,
      generatePassword,
      copyPassword,
      onInput
    }
  }
}
</script>