export default function handleError(error, customMessages = {}) {
    const errorResponse = {
        isError: true,
        isValidationError: false,
        message: 'Network Error.',
        type: 'error',
        errors: [],
        timeout: 2500,
    }
    const ErrorMessages = {
        400: 'There was some problem, while processing your request', // not being used currently
        401: 'Unauthorized, you are not allowed',
        403: 'Sorry, you are not allowed for this action',
        404: 'Api route is missing or undefined',
        405: 'Api route method not allowed',
        500: 'Server error, please try again later',
        request:
            'There is some problem with our servers, please try again later',
        other:
            'There was some problem with your request, please try again later',
    }
    if (Object.prototype.hasOwnProperty.call(customMessages, '400')) {
        ErrorMessages['400'] = customMessages['400']
    }
    if (Object.prototype.hasOwnProperty.call(customMessages, '401')) {
        ErrorMessages['401'] = customMessages['401']
    }
    if (Object.prototype.hasOwnProperty.call(customMessages, '403')) {
        ErrorMessages['403'] = customMessages['403']
    }
    if (Object.prototype.hasOwnProperty.call(customMessages, '404')) {
        ErrorMessages['404'] = customMessages['404']
    }
    if (Object.prototype.hasOwnProperty.call(customMessages, '405')) {
        ErrorMessages['405'] = customMessages['405']
    }
    if (Object.prototype.hasOwnProperty.call(customMessages, '500')) {
        ErrorMessages['500'] = customMessages['500']
    }
    if (Object.prototype.hasOwnProperty.call(customMessages, 'request')) {
        ErrorMessages.request = customMessages.request
    }
    if (Object.prototype.hasOwnProperty.call(customMessages, 'other')) {
        ErrorMessages.other = customMessages.other
    }
    if (error && error.response) {
        if (Object.prototype.hasOwnProperty.call(error.response, 'status')) {
            /* axios status*/
            // client received an error response (5xx, 4xx)
            if (error.response.status === 400) {
                // console.log('unauthorized, logging out ...');
                errorResponse.message = error.response.data.message
            } else if (error.response.status === 401) {
                // console.log('unauthorized, logging out ...');
                errorResponse.message = ErrorMessages['401']
            } else if (error.response.status === 403) {
                errorResponse.message = ErrorMessages['403']
            } else if (error.response.status === 404) {
                errorResponse.message = ErrorMessages['404']
            } else if (error.response.status === 422) {
                errorResponse.isValidationError = true
                errorResponse.errors = error.response.data.errors
                errorResponse.message = error.response.data.message
            } else if (error.response.status === 405) {
                errorResponse.message = ErrorMessages['405']
            } else if (error.response.status >= 500) {
                errorResponse.message = ErrorMessages['500']
            } else if (error.response.status === 429) {
                //
            }
        }

    } else if (error && error.request) {
        errorResponse.message = ErrorMessages.request
        // client never received a response, or request never left
    } else if (error instanceof Error) {
        errorResponse.message = error.message
    } else if (typeof error === 'string') {
        errorResponse.message = error
    } else {
        //this.$buefy.toast.open({
        //  message: 'Going Too Fast hun?, Please Slow Down',
        // type: 'is-danger',
        // })
        // anything else
        errorResponse.message = ErrorMessages.other
    }
    return errorResponse
}