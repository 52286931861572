<template>
  <div class="container py-5">
    <RouterView/>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed, onBeforeMount} from "vue";

export default {
  name: 'Home',
  setup() {
    const store = useStore();
    const router = useRouter();
    const auth = computed(() => store.getters['user/auth']);

    onBeforeMount(async () => {
      if (!auth.value.login) {
        await router.push({name: 'Login'});
      }
    });

  }
}
</script>