<template>
  <div class="d-flex flex-column align-items-center justify-content-center min-vh-100">
    <div class="d-flex text-black-70">
      <div class="pr-3 border-right">404</div>
      <div class="pl-3">NOT FOUND</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>