<template>
  <transition v-if="show" name="fade" ref="modal">
    <div :class="{'d-none':!show}" style="position:absolute; z-index: 1040">
      <div class="modal-backdrop fade show"></div>
      <div class="modal fade show d-block" @click.self="closeModal()">
        <div class="modal-dialog modal-dialog-centered" :class="'modal-'+modal_width">
          <div class="modal-content">
            <div class="modal-header" v-if="header">
              <h5 class="modal-title">{{ title }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal()"></button>
            </div>
            <div class="modal-body">
              <slot name="body" v-if="show"></slot>
            </div>
            <div class="modal-footer p-1" v-if="footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    header: {
      type: Boolean,
      default: true
    },
    title: String,
    footer: {
      type: Boolean,
      default: false
    },
    outSideClick: {
      type: Boolean,
      default: true
    },
    modal_width: {
      type: String,
      default: 'lg'
    },
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      const body = document.querySelector("body");
      body.classList.remove("modal-open");
      body.style = ''
    },
    openModal() {
      this.show = true;
      const body = document.querySelector("body");
      body.classList.add("modal-open");
      body.style = 'overflow: hidden; padding-right: 17px;'
    },
  },
  mounted() {
    this.$root.$el.parentElement.append(this.$el)
  },
  unmounted() {
    this.$el.parentNode.removeChild(this.$el);
  }
}
</script>
