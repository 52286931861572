import _ from "lodash"

let common = {
    mutations(initial_state) {
        return {
            resetState: (state) => {
                const s = initial_state
                Object.keys(s).forEach(key => {
                    state[key] = s[key]
                });
            }
        }
    }
}

let error = {
    state: {
        is_loading: false,
        errorResponse: {
            isError: true,
            isValidationError: false,
            message: '',
            type: 'error',
            errors: {},
            timeout: 0,
        }
    },
    getters: {
        isLoading: state => state.is_loading,
        getErrorResponse: state => state.errorResponse,
        getError: state => state.errorResponse.errors
    },
    mutations: {
        startLoading(state) {
            state.errorResponse.isError = false;
            state.errorResponse.errors = {};
            return state.is_loading = true;
        },
        stopLoading: state => state.is_loading = false,
        setErrorResponse: (state, error) => state.errorResponse = error,
        setErrors: (state, errors) => state.errorResponse.errors = errors
    }
}

let pagination = {
    state: {
        pagination: {
            current_page: 1,
            last_page: 1,
            from: 0,
            to: 0
        },
        search: '',
        filter: {},
        sort: {
            by: 'id',
            direction: 'asc',
            is_ordered: false,
        },
        current_page: 1,
    },
    getters: {
        getPagination: (state) => state.pagination,
        getPaginateData(state) {
            let params = {};
            if (state.search) {
                params.search = state.search;
            }
            if (state.filter) {
                Object.keys(state.filter).forEach(function (key) {
                    if (state.filter[key]) {
                        params[key] = state.filter[key];
                    }
                });
            }
            if (state.sort.is_ordered) {
                params.orderBy = state.sort.by;
                params.orderDirection = state.sort.direction;
            }
            if (state.pagination.current_page !== 1) {
                params.page = state.pagination.current_page;
            }
            return params;
        },
        getSearchValue: (state) => state.search
    },
    mutations: {
        setPagination: function (state, pagination) {
            return state.pagination = {
                current_page: pagination.current_page,
                from: pagination.from,
                last_page: pagination.last_page,
                per_page: pagination.per_page,
                to: pagination.to,
                total: pagination.total,
            };
        },
        setSearch: (state, search) => {
            state.pagination.current_page = 1
            state.search = search
        },
        setCurrentPage: (state, current_page) => (state.pagination.current_page = current_page),
        setOrderBy: (state, orderBy) => (state.sort.by = orderBy),
        setOrderDirection: (state, orderDirection) => (state.sort.direction = orderDirection),
        setIsOrdered: (state, is_ordered) => (state.sort.is_ordered = is_ordered),
        setFilter: (state, filter) => (state.filter = filter),
    }
}

function getPagination(pagination) {
    return {
        current_page: _.get(pagination, 'current_page', 1),
        from: _.get(pagination, 'from', 1),
        last_page: _.get(pagination, 'last_page', 1),
        per_page: _.get(pagination, 'per_page', 0),
        to: _.get(pagination, 'to', 1),
        total: _.get(pagination, 'total', 0),
    };
}

export {
    common,
    error,
    pagination,
    getPagination,
};