import axios from "axios";

let baseApi = axios.create({
    baseURL: process.env.VUE_APP_API
});

const apiRequest = function () {
    let token = localStorage.getItem('token');
    if (token) {
        baseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return baseApi;
}

export default apiRequest;
