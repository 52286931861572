<template>
  <div class="container py-5">
    <div class="d-flex justify-content-between">
      <h1 class="mb-5">Logo Maker</h1>
      <router-link :to="{name: 'Logout'}">Logout</router-link>
    </div>
    <div class="row">
      <div class="col-lg-5 mb-5">
        <FormGroupInput
            label="Product Name Here"
            :required="required.includes('product_name')"
            :value="form_data.product_name"
            :errors="errors.product_name"
            @input-value="form_data.product_name=$event"/>
        <FormGroupInput
            label="Subline Here"
            :attributes="{class: 'text-uppercase'}"
            :required="required.includes('subline')"
            :value="form_data.subline"
            :errors="errors.subline"
            @input-value="form_data.subline=$event.toUpperCase()"/>
        <FormGroupRadio
            label="Select Product Category"
            :options="productCategory"
            option-value="name"
            option-text="name"
            :required="required.includes('product_category')"
            :errors="errors.product_category"
            :value="form_data.product_category"
            @input-value="productCategoryChanged($event)"/>
        <FormGroupCheckBox
            label="Convert Text to Shape"
            :has_label="false"
            :options="['Convert Text to Shape']"
            :return-boolean="true"
            :required="required.includes('is_text_to_shape')"
            :checked="form_data.is_text_to_shape"
            :errors="errors.is_text_to_shape"
            @input-value="form_data.is_text_to_shape=$event"/>
      </div>
      <div class="col-lg-7">
        <div class="mb-3">
          <label for="customRange3" class="form-label">Zoom: </label>
          <input type="range" class="form-range" min="0.1" max="1" step="0.01" v-model="form_data.zoom" id="customRange3">
        </div>
        <div class="card mb-3" style="overflow-x: auto">
          <div ref="svgContainer" class="svgContainer">
            <SvgGenerator :title="form_data.product_name" :subtitle="form_data.subline" :color="form_data.color"/>
          </div>
        </div>
        <div class="d-flex gap-3 justify-content-evenly">
          <button class="btn btn-primary w-100" @click="downloadSVG" :disabled="isLoading">Download SVG</button>
          <button class="btn btn-success w-100" @click="downloadPNG" :disabled="isLoading">Download PNG</button>
          <button class="btn btn-secondary w-100" @click="downloadEPS" :disabled="isLoading">Download EPS</button>
          <button class="btn btn-dark w-100" @click="generateGraphics" :disabled="isLoading">Generate Graphics</button>
        </div>
      </div>
    </div>
    <Modal title="Preview" ref="generateGraphicsModal" modal_width="lg">
      <template v-slot:body>
        <div class="d-flex flex-column gap-3">
          <div class="position-relative">
            <div style="position:absolute;bottom: 5.3%;left: 44.2%;right: 0;top: 77.3%; display: flex; align-items: center">
              <img class="preview-logo w-100">
            </div>
            <img src="@/assets/img/templatra.jpg" class="w-100">
          </div>
          <div class="position-relative">
            <div style="position:absolute;bottom: 56.8%;left: 9.1%;right: 53.5%;top: 19.5%; display: flex; align-items: center">
              <img class="preview-logo w-100">
            </div>
            <img src="@/assets/img/templatrb.jpg" class="w-100">
          </div>
          <div class="position-relative">
            <div style="position:absolute;bottom: 49.8%;left: 37%;right: 37%;top: 41.5%; display: flex; align-items: center">
              <img class="preview-logo w-100">
            </div>
            <img src="@/assets/img/templatrp.jpg" class="w-100">
          </div>
        </div>
      </template>
    </Modal>
    <div class="d-none" id="svg-temp"></div>
  </div>
</template>

<script>
import FormGroupInput from "@/components/form-component/FormGroupInput";
import {computed, ref, watch} from "vue";
import FormGroupRadio from "@/components/form-component/FormGroupRadio";
import {saveAs} from 'file-saver';
import {Canvg, presets} from "canvg";
import {kebabCase} from "@/assets/script/type_cases";
import FormGroupCheckBox from "@/components/form-component/FormGroupCheckBox";
import SvgGenerator from "@/components/SvgGenerator";
import apiRequest from "@/axios";
import Modal from "@/components/Modal";


export default {
  name: "Editor",
  // eslint-disable-next-line vue/no-unused-components
  components: {Modal, SvgGenerator, FormGroupCheckBox, FormGroupRadio, FormGroupInput},
  setup() {
    const form_data = ref({
      product_name: 'BORBOS ELITE',
      subline: 'ADJUVANTE',
      color: '#00b2e2',
      padding: 10,
      product_category: 'ADJUVANTE',
      is_text_to_shape: true,
      zoom: 0.3
    });

    const required = ref([]);
    const errors = ref({});
    const errors_message = ref("");

    const generateGraphicsModal = ref(null);

    watch(() => form_data.value.zoom, function (value) {
      svgContainer.value.style.zoom = value;
    })

    const productCategory = ref([
      {name: "ADJUVANTE", color: "#00b2e2"},
      {name: "SPECIAL CHEMICALS", color: "#00b2e2"},
      {name: "HERBICIDE", color: "#47a141"},
      {name: "INSECTICIDE", color: "#9364cc"},
      {name: "FUNGICIDE", color: "#001970"},
      {name: "BIO-CONTROL", color: "#f4ce11"},
      {name: "INCENTIA", color: "#ff8600"},
      {name: "INCENTIA FOLIAR", color: "#001970"},
      {name: "INCENTIA PHYTO ECO ", color: "#8d1b0d"},
      {name: "INCENTIA ECO", color: "#176b2b"},
      {name: "INCENTIA EFFIK - C", color: "#ff8600"},
      {name: "INCENTIA CHELATE", color: "#e8b786"},
      {name: "INCENTIA OHC", color: "#ff8600"},
      {name: "INCENTIA SOLUBLE SOLID", color: "#533605"},
    ]);

    function productCategoryChanged(category) {
      form_data.value.color = category.color;
      form_data.value.subline = category.name;
      // const svgHTML = logos[category.toLowerCase()]
      // if (svgHTML) {
      //   svgContainer.value.innerHTML = svgHTML
      //   initSvg();
      // }
    }

    const fileName = computed(() => {
      let name = '';
      if (form_data.value.product_name) {
        name = form_data.value.product_name;
      } else if (form_data.value.subline) {
        name = form_data.value.subline
      }
      return name ? kebabCase(name) + '-logo' : 'logo';
    });

    const isLoading = ref(false);
    const svgContainer = ref(null);


    async function downloadSVG() {
      isLoading.value = true;
      const svgHTML = await getSVG();
      const exportSVG = createSVG(svgHTML);
      const blob = new Blob([exportSVG], {
        type: 'text/plain;charset=utf-8',
      });
      await saveAs(blob, fileName.value + '.svg');
      isLoading.value = false;

    }

    async function downloadPNG() {
      isLoading.value = true;
      const svgHTML = await getSVG()

      const canvas = new OffscreenCanvas(svgContainer.value.clientWidth * 2, svgContainer.value.clientHeight * 2);
      const ctx = canvas.getContext('2d');
      const v = await Canvg.from(ctx, svgHTML, presets.offscreen());
      await v.render();
      const png = await canvas.convertToBlob();

      await saveAs(png, fileName.value + '.png')
      isLoading.value = false;
    }

    async function downloadEPS() {
      try {
        isLoading.value = true;
        const svgHTML = await getSVG()
        console.log(svgHTML)
        const response = await apiRequest().post('vector-express',
            {file: svgHTML},
        )
        isLoading.value = false;
        const blob = new Blob([response.data], {
          type: 'image/svg+xml',
        });
        saveAs(blob, fileName.value + '.eps')
      } catch (error) {
        console.log(error)
        isLoading.value = false;
      }
    }

    function createSVG(svgHTML) {
      const div = document.createElement('div');
      div.innerHTML = svgHTML;
      let svg = div.querySelector('svg');
      svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      return div.innerHTML;
    }

    async function generateGraphics() {
      generateGraphicsModal.value.openModal();


        const svgHTML = await getSVG()

        const canvas = new OffscreenCanvas(svgContainer.value.clientWidth * 2, svgContainer.value.clientHeight * 2);
        const ctx = canvas.getContext('2d');
        const v = await Canvg.from(ctx, svgHTML, presets.offscreen());
        await v.render();
        const png = await canvas.convertToBlob();
        Array.from(document.querySelectorAll('.preview-logo')).forEach(img => img.src = URL.createObjectURL(png))
      isLoading.value = false;
    }


    async function getSVG() {
      let svgHTML = svgContainer.value.innerHTML
      if (form_data.value.is_text_to_shape) {
        /* eslint-disable */
        const svgTempElementContainer = document.querySelector('#svg-temp');
        svgTempElementContainer.innerHTML = svgHTML
        await SvgTextToPath.replaceAll(document.querySelector('#svg-temp'), {
          handlers: [SvgTextToPath.handlers.map, SvgTextToPath.handlers.google],
          googleApiKey: 'AIzaSyCUGgPb4XlMePlgi-AQTrWzniywE5lx2s8',
          // group: true
        });
        svgHTML = svgTempElementContainer.innerHTML;
        /* eslint-enable */
      }
      return svgHTML;
    }


    return {
      form_data,
      required,
      errors,
      errors_message,
      productCategory,
      svgContainer,
      downloadSVG,
      downloadPNG,
      downloadEPS,
      isLoading,
      productCategoryChanged,
      generateGraphics,
      generateGraphicsModal
    }
  }
}
</script>
<style>
.square {
  position: relative;
  width: 100%;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Rubik-Black';
  src: local('Rubik');
}

.svgContainer {
  zoom: 0.3;
  min-height: 300px;
}
</style>